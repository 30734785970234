import React from "react"
import styled from "styled-components"
import Seo from "../components/seo"

import { useEffect, useContext } from "react"
import { navigate } from "gatsby"
import { GlobalAuthContext } from "../Contexts/GlobalAuthContext"
import { Link } from "gatsby"
import {
  calculateOcrUpdate,
  determineLocalCurrencyBasedOnAllowedCurrencies,
  productPricingInformation,
} from "../components/helpers/PriceCalculator"
import Logo from "../icons/PDF-logo-vector.svg"

const Title = styled.h1`
  max-width: 628.62px;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 52px;
  line-height: 58px;
  /* or 112% */
  width: 80%;
  margin-bottom: 60px;
  margin-top: 50px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #111111;

  @media (max-width: 480px) {
    font-size: 32px;
    line-height: 36px;
  }
`
const Paragraph = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  ${props => props?.margin && `margin-top: 60px;`}
  ${props => props?.marginBottom && `margin-bottom: 60px;`}
  width: 95%;
  @media (max-width: 480px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    width: 296px;
  }

  a {
    display: inline;
  }
  color: #222222;
`
const Style = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
  background: #ffffff;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 880px;
    box-sizing: border-box;
    margin: 0px 24px;
    span {
      @media (max-width: 480px) {
        font-size: 18px !important;
      }
    }

    .button-group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 0px;
      width: 100%;
      height: max-content;
      flex: none;
      order: 2;
      flex-grow: 0;
      margin: 48px 0px;

      @media (max-width: 760px) {
        flex-direction: column;
      }

      .button-link {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 24px;
        border: 2px solid #007de6;
        box-sizing: border-box;
        border-radius: 60px;
        width: 310px;
        height: 60px;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 16px;
        text-decoration: none;

        @media (max-width: 760px) {
          margin: 16px 0px;
        }

        @media (max-width: 480px) {
          width: 280px;
          font-size: 16px;
        }

        .button-text {
          width: max-content;
          height: 27px;
          font-family: Work Sans;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 27px;
          text-align: center;
          color: #007de6;
          flex: none;
          order: 0;
          flex-grow: 0;
          margin: 0px 10px;
        }
      }
    }
  }
`

const YouOrder = styled.div`
  font-weight: 400;
  font-size: 32px;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #222222;
  min-width: 401px;

  border: 1px solid #dcdcdc;
  padding: 32px 64px;
  max-width: 735px;
  width: 90%;

  @media (max-width: 480px) {
    font-size: 24px;
    line-height: 32px;
  }
`

const AtricleHolder = styled.div`
  border: 1px solid #dcdcdc;
  border-top: none;
  padding: 32px 64px;
  max-width: 735px;
  width: 90%;
  background: #fbfbfb;
  display: flex;
  flex-direction: column;
  min-width: 401px;
  ${props => props?.mobile && `display: none;`}

  @media (max-width: 480px) {
    ${props => props?.desktop && `display: none;`}
    ${props => props?.mobile && `display: flex;`}
  }
`
const Article = styled.div`
  display: flex;
  width: max-content;
`

const Orders = styled.div`
  background: #00ca42;
  border-radius: 4px;
  height: 21px;
  min-width: 29px;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  margin-right: 8px;
`

const ArticleTitle = styled.div`
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #222222;
`

const AtriclePrice = styled.div`
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #222222;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props => props.margin && "  margin: 16px 0; "}
`
const Renewal = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  margin: 16px 0;
  display: flex;
  align-items: center;

  color: #8d8d8d;
`

const LinkAccount = styled(Link)`
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #0000ee;
`

const ArticleText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
`

const Tax = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #8d8d8d;
`
const Reference = styled.div`
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
`

const Saved = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #00ca42;
`

const Redirect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  overflow: hidden;
  margin-top: 24px;

  svg {
    transform: scale(5);
    margin-bottom: 96px;
    stroke: #e44331;
  }

  div {
    font-size: 48px;
  }
`

function Purchased(props) {
  const { isLoggedIn } = useContext(GlobalAuthContext)
  // after successfull purchase it will start download automatically
  function delayedDownload() {
    console.log("do I happen")
    navigate(
      "https://1543d3a591e926011c7a-9b1b76dc96f52415d7c2bfd8c3a40309.ssl.cf2.rackcdn.com/PDFProInstaller.exe"
    )
  }

  console.log(props)
  let purchaseData = {
    id: "S6Fnql6ZQSqguvNLK2Re1Q",
    reference: "PDF230124-2726-69110",
    buyerReference: null,
    live: false,
    currency: "EUR",
    payoutCurrency: "EUR",
    total: 91.5,
    totalDisplay: "€91.50",
    totalInPayoutCurrency: 91.5,
    totalInPayoutCurrencyDisplay: "€91.50",
    tax: 18.3,
    taxDisplay: "€18.30",
    taxInPayoutCurrency: 18.3,
    taxInPayoutCurrencyDisplay: "€18.30",
    subtotal: 73.2,
    subtotalDisplay: "€73.20",
    subtotalInPayoutCurrency: 73.2,
    subtotalInPayoutCurrencyDisplay: "€73.20",
    discount: 44.4,
    discountDisplay: "€44.40",
    discountInPayoutCurrency: 44.4,
    discountInPayoutCurrencyDisplay: "€44.40",
    discountWithTax: 55.5,
    discountWithTaxDisplay: "€55.50",
    discountWithTaxInPayoutCurrency: 55.5,
    discountWithTaxInPayoutCurrencyDisplay: "€55.50",
    payment: {
      type: "test",
      cardEnding: "4242",
    },
    account: "DO7-lDAbRCisqq0EIEl8Kw",
    tags: {
      source: "direct",
      utm_source: "null",
      utm_content: "null",
      utm_term: "null",
      utm_campaign: "null",
      utm_medium: "null",
    },
    items: [
      {
        product: "pdf-one-year-ocr",
        quantity: 1,
        coupon: null,
        sku: null,
        subtotal: 73.2,
        subtotalDisplay: "€73.20",
        subtotalInPayoutCurrency: 73.2,
        subtotalInPayoutCurrencyDisplay: "€73.20",
        attributes: {
          custom_field_Billing: "annual",
          assign_customer_as_user: "true",
          custom_field_OCR_URL:
            "https://29c388664f8967d141fb-8548cc0479d1cf03dc138af18dcc0f1b.ssl.cf2.rackcdn.com/new/ocr/ocr.zip",
          valid_duration: "1y",
          product_short_code: "pdf",
          is_license_manager: "true",
          uuid: "8fa80ed5-008c-4622-b136-fcd67f81de6d",
        },
        discount: 44.4,
        discountDisplay: "€44.40",
        discountInPayoutCurrency: 44.4,
        discountInPayoutCurrencyDisplay: "€44.40",
        subscription: "iwcVIRafTneYyUZHtCecGw",
        fulfillments: {},
        driver: {
          type: "cross-sell",
          path: "pdf-pro-popup-pdf-pro",
        },
      },
      {
        product: "ocr",
        quantity: 1,
        coupon: null,
        sku: null,
        subtotal: 0,
        subtotalDisplay: "€0.00",
        subtotalInPayoutCurrency: 0,
        subtotalInPayoutCurrencyDisplay: "€0.00",
        attributes: {
          product_short_code: "pdf",
          feature_code: "ocr",
          uuid: "8fa80ed5-008c-4622-b136-fcd67f81de6d",
        },
        discount: 0,
        discountDisplay: "€0.00",
        discountInPayoutCurrency: 0,
        discountInPayoutCurrencyDisplay: "€0.00",
        fulfillments: {},
        driver: {
          type: "configuration",
          path: "pdf-one-year-ocr",
        },
      },
      {
        product: "edit-text-images",
        quantity: 1,
        coupon: null,
        sku: null,
        subtotal: 0,
        subtotalDisplay: "€0.00",
        subtotalInPayoutCurrency: 0,
        subtotalInPayoutCurrencyDisplay: "€0.00",
        attributes: {
          product_short_code: "pdf",
          feature_code: "edit-text-images",
          uuid: "8fa80ed5-008c-4622-b136-fcd67f81de6d",
        },
        discount: 0,
        discountDisplay: "€0.00",
        discountInPayoutCurrency: 0,
        discountInPayoutCurrencyDisplay: "€0.00",
        fulfillments: {},
        driver: {
          type: "configuration",
          path: "pdf-one-year-ocr",
        },
      },
    ],
  }

  purchaseData = props.location.state?.purchase
  useEffect(() => {
    if (!purchaseData) {
      navigate("/login", { replace: true })
    }else{
      // GA4 - Custom Event
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
      event: "purchase",
      eventName: "purchase",
      transaction_id: purchaseData.reference,
      value: purchaseData.total,
      currency: purchaseData.currency,
      items: purchaseData.items
      })
    }
  }, [])

  useEffect(() => {
    if (isLoggedIn === false) {
      //&& !purchaseData
      delayedDownload()
    }
  }, [isLoggedIn])

  if (!purchaseData) {
    return (
      <Redirect>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M14 16l6-6-6-6" />
          <path d="M4 21v-7a4 4 0 0 1 4-4h11" />
        </svg>
        <div>Redirecting...</div>
      </Redirect>
    )
  }

  const productPath = purchaseData?.items[0]?.product
    ?.replace("-ocr", "")
    ?.replace("-multi", "")

  const discountTotalnoMonth = !productPath?.includes("perpetual")
    ? determineLocalCurrencyBasedOnAllowedCurrencies(productPath) +
      productPricingInformation(productPath).discountTotalPriceInteger
    : determineLocalCurrencyBasedOnAllowedCurrencies(productPath) +
      productPricingInformation(productPath).totalPriceInteger

  const discountTotalMonth =
    determineLocalCurrencyBasedOnAllowedCurrencies(productPath) +
    productPricingInformation(productPath).pricePerMonthInteger

  const discountTotal = productPath?.includes("monthly")
    ? discountTotalMonth
    : discountTotalnoMonth

  function addDate(unit, value) {
    var today = new Date()
    if (unit === "month") {
      today.setMonth(today.getMonth() + value)
    } else if (unit === "year") {
      today.setFullYear(today.getFullYear() + value)
    }
    var dd = String(today.getDate()).padStart(2, "0")
    var mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
    var yyyy = today.getFullYear()
    return mm + "/" + dd + "/" + yyyy
  }

  let dateValue
  let unit
  if (productPath?.includes("one-year")) {
    dateValue = 1
    unit = "year"
  }
  if (productPath?.includes("two-year")) {
    dateValue = 2
    unit = "year"
  }
  if (productPath?.includes("monthly")) {
    dateValue = 1
    unit = "month"
  }

  const RenewalDate = addDate(unit, dateValue)
  return (
    <Style>
      <Seo title="Purchased Page" />
      <div className="container">
        <Title>Thank you for your purchase!</Title>
        <Paragraph marginBottom>
          <div>
            You may download your copy of PDF Pro{" "}
            <a href="https://1543d3a591e926011c7a-9b1b76dc96f52415d7c2bfd8c3a40309.ssl.cf2.rackcdn.com/PDFProInstaller.exe">
              here
            </a>{" "}
            .{" "}
          </div>
        </Paragraph>
        <YouOrder>
          {" "}
          Your Order <Logo />
        </YouOrder>
        <AtricleHolder>
          <Row>
            <Article>
              <Orders>x{purchaseData?.items[0]?.quantity}</Orders>
              <ArticleTitle>PDF Pro</ArticleTitle>
            </Article>
            <AtriclePrice>
              {discountTotal?.substring(0, 1) +
                parseInt(discountTotal?.substring(1))?.toFixed(2)}
            </AtriclePrice>
          </Row>
          <Renewal>
            {!productPath.includes("perpetual") &&
              `Subscription renews every ${dateValue} ${unit}. Next charge ${RenewalDate}(
            ${purchaseData?.totalDisplay[0]}
            ${parseInt(
              purchaseData?.totalInPayoutCurrency +
                purchaseData?.discountWithTaxInPayoutCurrency
            )?.toFixed(2)}
            ).`}

            {productPath.includes("perpetual") && `One time fee`}
          </Renewal>
          <LinkAccount
            to="https://pdf-pro.onfastspring.com/account"
            target="_blank"
          >
            Full Terms and Subscription Management
          </LinkAccount>
        </AtricleHolder>
        <AtricleHolder>
          <Row>
            <Article>
              <Orders>x{purchaseData?.items[0]?.quantity}</Orders>
              <ArticleTitle>
                {purchaseData?.items[2] ? "OCR" : "Edit Text/Images"}
              </ArticleTitle>
            </Article>
            <AtriclePrice>
              {console.log(purchaseData?.items[0]?.product)}
              {purchaseData?.items[2]
                ? `${determineLocalCurrencyBasedOnAllowedCurrencies(
                    productPath
                  )}${
                    productPath.includes("monthly")
                      ? (
                          calculateOcrUpdate(productPath).monthlyPrice * 2
                        )?.toFixed(2)
                      : productPath.includes("perpetual")
                      ? calculateOcrUpdate(
                          productPath
                        ).discountOcrPrice?.toFixed(2)
                      : (
                          calculateOcrUpdate(productPath).discountOcrPrice * 2
                        )?.toFixed(2)
                  }`
                : ""}
            </AtriclePrice>
          </Row>
        </AtricleHolder>
        {purchaseData?.items[2] && (
          <AtricleHolder>
            <Row>
              <Article>
                <Orders>x{purchaseData?.items[0]?.quantity}</Orders>
                <ArticleTitle>Edit Text/Images</ArticleTitle>
              </Article>
              <AtriclePrice>{}</AtriclePrice>
            </Row>
          </AtricleHolder>
        )}
        <AtricleHolder desktop>
          <Row>
            <ArticleText>
              Changes will appear on your bills as PP*FS PDF Pro Soft
            </ArticleText>
            <Tax>Tax: {purchaseData?.taxDisplay}</Tax>
          </Row>
          <Row margin>
            <Article>
              <Reference>Order Reference:&nbsp;</Reference>
              <LinkAccount
                to="https://pdf-pro.onfastspring.com/account"
                target="_blank"
              >
                {purchaseData?.reference}
              </LinkAccount>
            </Article>
            <Saved>You Saved: {purchaseData?.discountWithTaxDisplay}</Saved>
          </Row>
          <Row>
            {" "}
            <LinkAccount
              to={`https://pdf-pro.onfastspring.com/popup-pdf-pro/account/order/${purchaseData?.reference}/invoice/`}
              target="_blank"
            >
              View invoice
            </LinkAccount>
            <AtriclePrice> Total: {purchaseData?.totalDisplay}</AtriclePrice>
          </Row>
        </AtricleHolder>
        <AtricleHolder mobile>
          <Row>
            <Tax>Tax: {purchaseData?.taxDisplay}</Tax>
          </Row>
          <Row margin>
            <Saved>You Saved: {purchaseData?.discountWithTaxDisplay}</Saved>
          </Row>
          <Row>
            <AtriclePrice> Total: {purchaseData?.totalDisplay}</AtriclePrice>
          </Row>
          <Row margin>
            <ArticleText>
              Changes will appear on your bills as PP*FS PDF Pro Soft
            </ArticleText>
          </Row>
          <Row>
            <Article>
              <Reference>Order Reference:&nbsp;</Reference>
              <LinkAccount
                to="https://pdf-pro.onfastspring.com/account"
                target="_blank"
              >
                {purchaseData?.reference}
              </LinkAccount>
            </Article>
          </Row>
          <Row margin>
            {" "}
            <LinkAccount
              to={`https://pdf-pro.onfastspring.com/popup-pdf-pro/account/order/${purchaseData?.reference}/invoice/`}
              target="_blank"
            >
              View invoice
            </LinkAccount>
          </Row>
        </AtricleHolder>

        <Paragraph margin>
          <div>
            Please check your email for instructions on activation of your copy
            of PDF Pro and for accessing your account.
          </div>
        </Paragraph>

        <div className="button-group">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://support.pdfpro.com/hc/en-us/categories/900000414286-Knowledge-Base"
            className="button-link"
          >
            <span className="button-text">Knowledge Base</span>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://support.pdfpro.com/hc/en-us/requests/new"
            className="button-link"
          >
            <span className="button-text">Submit a Support Request</span>
          </a>
        </div>
      </div>
    </Style>
  )
}

export default Purchased
